import { TFunction } from 'i18next';
import * as Yup from 'yup';

// See https://github.com/jquense/yup/blob/master/src/locale.ts for structure suggestion
export const buildYupLocale = (_: unknown, t: TFunction) => {
  Yup.setLocale({
    mixed: {
      required: () => t('common.required'),
    },
    number: {
      min: ({ min }: { min: number }) => t('common.number.min', { min }),
      max: ({ max }: { max: number }) => t('common.number.max', { max }),
      integer: t('common.noDecimals'),
    },
    string: {
      min: ({ min }: { min: number }) => t('common.minLength', { length: min }),
      max: ({ max }: { max: number }) => t('common.maxLength', { length: max }),
      email: t('common.emailNotValid'),
    },
  });
};
