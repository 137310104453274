import { init as initApm } from '@elastic/apm-rum';

const apm = initApm({
  environment: import.meta.env.VITE_ENVIRONMENT as string,
  active: !!import.meta.env.VITE_APM_SERVER_URL && !!import.meta.env.VITE_ENVIRONMENT,
  serviceName: 'frontend',
  serverUrl: import.meta.env.VITE_APM_SERVER_URL as string,
});

export default apm;
