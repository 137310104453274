import { RefObject } from 'react';

export default (ref: RefObject<HTMLInputElement>, updateState: (input: string) => void) => {
  if (ref.current) {
    let typingTimer: NodeJS.Timeout;
    const doneTypingInterval = 1000;

    ref.current.addEventListener(
      'keydown',
      (e) => {
        clearTimeout(typingTimer);

        if (ref?.current?.value === '' || ref.current?.value) {
          typingTimer = setTimeout(() => updateState((e.target as HTMLInputElement).value.trim()), doneTypingInterval);
        }
      },
      { once: true },
    );
  }
};
