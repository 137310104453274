import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { isEmpty, omit } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';

import ChakraChevron from 'components/Form/ChakraChevron';
import { EntityWithId } from 'shared/types/EntityWithId';
import handleSearch from 'utils/handleSearch';

import Dropdown from './Dropdown';
import MultiSelectLabel from './MultiSelectLabel';
import SingleSelectLabel from './SingleSelectLabel';
import { DROPDOWN_VARIANT } from './constants';
import { CommonProps, DropdownTriggerConditionalProps, ExtractData, LabelConditionalProps, QueryTypeLike, VariantConditionalProps } from './structures';

const SearchDropdown = <QueryType extends QueryTypeLike, Variables>(
  props: CommonProps<QueryType, Variables> &
    LabelConditionalProps<QueryType> &
    DropdownTriggerConditionalProps<QueryType> &
    VariantConditionalProps<QueryType>,
) => {
  const { t } = useTranslation();
  const { onOpen, isOpen, onToggle, onClose } = useDisclosure();
  const popoverContent = useRef<HTMLElement>(null);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');
  const {
    variables,
    title,
    portalContainerRef,
    customTrigger: CustomTrigger,
    placement,
    size = 'sm',
    isReadOnly,
    extraOptions,
    dataFilter,
    tabIndex = 0,
    hideSelectBox = false,
    onCompleted,
    selected,
    ...buttonProps
  } = props;

  const onKeyDown = useCallback(() => handleSearch(initialFocusRef, setSearch), []);

  const isDirty = props.selected?.length !== 0;

  const openDropdown = () => {
    if (!isReadOnly) {
      setSearch('');
      onOpen();
    }
  };

  const select = (value: EntityWithId<Partial<ExtractData<QueryType>>>) => {
    if (props.dropdownVariant === DROPDOWN_VARIANT.SINGLE) {
      props.onSelect(value);
      onClose();
    } else if (props.dropdownVariant === DROPDOWN_VARIANT.MULTI_SELECT) {
      let values = props.selected ?? [];
      if (props.selected?.some((element) => element._id === value._id)) {
        values = values.filter((element) => element._id !== value._id);
      } else {
        values = [...values, value];
      }

      props.onSelect(values);
    }
  };

  useOutsideClick({
    ref: popoverContent,
    handler: () => {
      popoverContent.current?.querySelectorAll('input').forEach((element) => {
        element.nextElementSibling?.removeAttribute('data-focus');
      });
      onClose();
    },
  });

  return (
    <Popover initialFocusRef={initialFocusRef} isLazy placement={placement ?? 'bottom-start'} isOpen={isOpen}>
      <PopoverTrigger>
        <Box onClick={() => !isReadOnly && openDropdown()}>
          {CustomTrigger ? (
            <CustomTrigger
              isOpen={isOpen}
              selected={props.selected}
              deselect={select}
              query={props.query}
              tabIndex={tabIndex}
              isReadOnly={isReadOnly}
              {...omit(buttonProps, 'query')}
            />
          ) : (
            <Button
              minH="32px"
              h="auto"
              justifyContent="space-between"
              variant="outline"
              color={isDirty ? 'gray.500' : 'gray.700'}
              cursor={isReadOnly ? 'auto' : 'pointer'}
              rightIcon={<ChakraChevron isOpen={isOpen} hidden={isReadOnly} />}
              tabIndex={tabIndex}
              fontSize="sm"
              size={size}
              boxShadow={isOpen ? 'outline' : 'none'}
              fontWeight="normal"
              {...omit(buttonProps, 'onSelect')}
            >
              {props.dropdownVariant === DROPDOWN_VARIANT.SINGLE ? (
                <SingleSelectLabel<QueryType> placeholder={title || t('common.select')} selected={selected?.[0]} {...omit(props, 'selected')} />
              ) : (
                <MultiSelectLabel<QueryType> placeholder={title || t('common.select')} deselect={select} {...props} />
              )}
            </Button>
          )}
        </Box>
      </PopoverTrigger>
      <Portal containerRef={portalContainerRef}>
        <PopoverContent ref={popoverContent} zIndex={999} w="300px" overflow="hidden">
          <Box d="inline-block" w="100%">
            <Box m="2.5" hidden={hideSelectBox}>
              <InputGroup size="sm">
                <InputLeftElement>
                  <Icon as={FiSearch} />
                </InputLeftElement>
                <Input ref={initialFocusRef} defaultValue={search} onKeyDown={onKeyDown} size="sm" placeholder={t('common.search')} />
              </InputGroup>
            </Box>
            {!CustomTrigger && props.dropdownVariant === DROPDOWN_VARIANT.MULTI_SELECT && !isEmpty(selected) && (
              <HStack gap="1" padding="0 .5em">
                <MultiSelectLabel<QueryType> placeholder="Select" deselect={select} {...omit(props, 'maxElementsToShow')} />
              </HStack>
            )}
            {
              <Dropdown<QueryType, Variables>
                variables={variables}
                search={search}
                onClose={onClose}
                onToggle={onToggle}
                extraOptions={extraOptions}
                dataFilter={dataFilter}
                select={select}
                onCompleted={onCompleted}
                {...props}
              />
            }
          </Box>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default SearchDropdown;
