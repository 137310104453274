import React, { lazy, Suspense } from 'react';
import { TbBox, TbBuildingStore, TbCalendar, TbCheckbox, TbClock, TbCurrencyDollar, TbDashboard, TbSettings, TbStars, TbUsers } from 'react-icons/tb';

import { OnboardingStep } from '__types/graphql';
import { useAbility } from 'client/ability';
import Loading from 'components/Loading';
import { TenantStore } from 'components/TenantRouter/RouterSwitcher/store';
import Page from 'shared/types/Page';

import FallbackFront from './FallbackFront';
import NotFoundTenant from './NotFoundTenant';

const Dashboard = lazy(() => import('./Dashboard'));
const Leaderboard = lazy(() => import('./Leaderboard'));
const Profile = lazy(() => import('./Profile'));
const Calendar = lazy(() => import('./Calendar'));
const EventRedirection = lazy(() => import('./Calendar/EventRedirection'));
const Customers = lazy(() => import('./Customers/List'));
const Customer = lazy(() => import('./Customers/Card'));
const CreateCustomer = lazy(() => import('./Customers/Create'));
const Tasks = lazy(() => import('./Tasks'));
const PeopleList = lazy(() => import('./People/List'));
const TimeTrackings = lazy(() => import('./TimeTracking/List'));
const Sales = lazy(() => import('./Sale/List'));
const Sale = lazy(() => import('./Sale/Item'));
const CreateSale = lazy(() => import('./Sale/Create'));
const Orders = lazy(() => import('./Orders/List'));
const Order = lazy(() => import('./Orders/Card'));
const CreateOrder = lazy(() => import('./Orders/Create'));
const Conversation = lazy(() => import('./Conversation'));
const Admin = lazy(() => import('./Admin'));
const Onboarding = lazy(() => import('./Onboarding'));

export default () => {
  const ability = useAbility();
  const organization = TenantStore.useStoreState((store) => store.organization);
  const isOnboardingClosed = organization?.onboardingStep === OnboardingStep.closed;

  const hasAdminAbilities = () => {
    return (
      ability.can('read', 'User') ||
      ability.can('read', 'Role') ||
      ability.can('read', 'Contract') ||
      ability.can('read', 'Product') ||
      ability.can('signatureRequest', 'ConversationTemplate') ||
      ability.can('create', 'Product') ||
      ability.can('update', 'Organization') ||
      ability.can('import', 'Organization')
    );
  };

  const hasOrderAbilities = () => {
    return ability.can('read', 'Order') && ability.can('module', 'Order');
  };

  const hasTimeTrackingAbilities = () => {
    return ability.can('read', 'TimeTracking') && ability.can('module', 'TimeTracking');
  };
  const pages: Page[] = [
    {
      type: 'page',
      name: 'Dashboard',
      menu: true,
      element: (
        <Suspense fallback={<Loading />}>
          <Dashboard />
        </Suspense>
      ),
      path: '',
      index: true,
      exact: true,
      secure: true,
      icon: TbDashboard,
      containerStyles: {
        padding: '0 0',
      },
      permission: ability.can('read', 'Dashboard') && isOnboardingClosed,
    },
    {
      menu: false,
      type: 'page',
      name: 'Home',
      path: '',
      index: true,
      element: <FallbackFront />,
      secure: true,
      exact: true,
      permission: isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'CalendarRedirection',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <EventRedirection />
        </Suspense>
      ),
      path: 'calendar/openEvent/:eventId',
      exact: true,
      secure: true,
      permission: ability.can('read', 'Event') && isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'Calendar',
      menu: true,
      element: (
        <Suspense fallback={<Loading />}>
          <Calendar />
        </Suspense>
      ),
      link: '/calendar',
      path: 'calendar/*',
      exact: true,
      secure: true,
      icon: TbCalendar,
      containerStyles: {
        padding: '0 0',
      },
      permission: ability.can('read', 'Event') && isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'Tasks',
      menu: true,
      element: (
        <Suspense fallback={<Loading />}>
          <Tasks />
        </Suspense>
      ),
      exact: true,
      secure: true,
      icon: TbCheckbox,
      path: 'tasks',
      link: '/tasks',
      permission: ability.can('read', 'Task') && isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'Task',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <Tasks />
        </Suspense>
      ),
      exact: true,
      secure: true,
      path: 'tasks/:taskId',
      permission: ability.can('read', 'Task') && isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'Leaderboard',
      menu: true,
      path: 'leaderboard',
      link: '/leaderboard',
      element: (
        <Suspense fallback={<Loading />}>
          <Leaderboard />
        </Suspense>
      ),
      exact: true,
      secure: true,
      icon: TbStars,
      permission: ability.can('leaderboard', 'Dashboard') && isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'Customers list',
      menu: true,
      element: (
        <Suspense fallback={<Loading />}>
          <Customers />
        </Suspense>
      ),
      path: 'customers',
      link: '/customers',
      exact: true,
      secure: true,
      icon: TbBuildingStore,
      permission: ability.can('read', 'Customer') && isOnboardingClosed,
    },
    {
      name: 'Customer item',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <Customer />
        </Suspense>
      ),
      path: 'customers/card/:customerId',
      exact: true,
      secure: true,
      permission: ability.can('read', 'Customer') && isOnboardingClosed,
    },
    {
      name: 'Customer item - task',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <Customer />
        </Suspense>
      ),
      path: 'customers/card/:customerId/:tabName/task/:taskId',
      exact: true,
      secure: true,
      permission: ability.can('read', 'Customer') && isOnboardingClosed,
    },
    {
      name: 'Customer item - tab entity',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <Customer />
        </Suspense>
      ),
      path: 'customers/card/:customerId/:tabName/:entityId',
      exact: true,
      secure: true,
      permission: ability.can('read', 'Customer') && isOnboardingClosed,
    },
    {
      name: 'Customer item - tab',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <Customer />
        </Suspense>
      ),
      path: 'customers/card/:customerId/:tabName',
      exact: true,
      secure: true,
      permission: ability.can('read', 'Customer') && isOnboardingClosed,
    },
    {
      name: 'Customer create',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <CreateCustomer />
        </Suspense>
      ),
      path: 'customers/create',
      exact: true,
      secure: true,
      permission: ability.can('read', 'Customer') && isOnboardingClosed,
    },
    {
      name: 'People',
      menu: true,
      element: (
        <Suspense fallback={<Loading />}>
          <PeopleList />
        </Suspense>
      ),
      exact: true,
      secure: true,
      icon: TbUsers,
      path: 'people/*',
      link: '/people',
      permission: ability.can('read', 'Person') && isOnboardingClosed,
    },
    {
      name: 'Time Tracking',
      menu: true,
      element: (
        <Suspense fallback={<Loading />}>
          <TimeTrackings />
        </Suspense>
      ),
      exact: true,
      secure: true,
      icon: TbClock,
      path: 'time-tracking/',
      link: '/time-tracking',
      permission: hasTimeTrackingAbilities() && isOnboardingClosed,
    },
    {
      name: 'Time Tracking edit',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <TimeTrackings />
        </Suspense>
      ),
      exact: true,
      secure: true,
      path: 'time-tracking/:timeTrackingId',
      permission: hasTimeTrackingAbilities() && isOnboardingClosed,
    },
    {
      name: 'Time Tracking - task',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <TimeTrackings />
        </Suspense>
      ),
      exact: true,
      secure: true,
      path: 'time-tracking/task/:taskId',
      permission: hasTimeTrackingAbilities() && isOnboardingClosed,
    },
    {
      name: 'Sale list',
      menu: true,
      element: (
        <Suspense fallback={<Loading />}>
          <Sales />
        </Suspense>
      ),
      exact: true,
      secure: true,
      icon: TbCurrencyDollar,
      path: 'sale/',
      link: '/sale',
      permission: ability.can('read', 'Sale') && isOnboardingClosed,
    },
    {
      name: 'Sale item',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <Sale />
        </Suspense>
      ),
      exact: true,
      secure: true,
      path: 'sale/item/:saleId',
      permission: ability.can('read', 'Sale') && isOnboardingClosed,
    },
    {
      name: 'Sale item - tab',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <Sale />
        </Suspense>
      ),
      exact: true,
      secure: true,
      path: 'sale/item/:saleId/:tabName',
      permission: ability.can('read', 'Sale') && isOnboardingClosed,
    },
    {
      name: 'Sale item - task',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <Sale />
        </Suspense>
      ),
      exact: true,
      secure: true,
      path: 'sale/item/:saleId/:tabName/task/:taskId',
      permission: ability.can('read', 'Sale') && isOnboardingClosed,
    },
    {
      name: 'Sale item - tab entity',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <Sale />
        </Suspense>
      ),
      exact: true,
      secure: true,
      path: 'sale/item/:saleId/:tabName/:entityId',
      permission: ability.can('read', 'Sale') && isOnboardingClosed,
    },
    {
      name: 'Sale create',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <CreateSale />
        </Suspense>
      ),
      exact: true,
      secure: true,
      path: 'sale/create',
      permission: ability.can('read', 'Sale') && isOnboardingClosed,
    },
    {
      name: 'Sale edit',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <CreateSale />
        </Suspense>
      ),
      exact: true,
      secure: true,
      path: 'sale/edit/:saleId',
      permission: ability.can('read', 'Sale') && isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'Orders',
      menu: true,
      exact: true,
      secure: true,
      icon: TbBox,
      element: (
        <Suspense fallback={<Loading />}>
          <Orders />
        </Suspense>
      ),
      path: 'orders',
      link: '/orders',
      permission: hasOrderAbilities() && isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'Order',
      menu: false,
      exact: true,
      secure: true,
      element: (
        <Suspense fallback={<Loading />}>
          <Order />
        </Suspense>
      ),
      path: 'orders/item/:orderId',
      permission: hasOrderAbilities() && isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'Order - tab',
      menu: false,
      exact: true,
      secure: true,
      element: (
        <Suspense fallback={<Loading />}>
          <Order />
        </Suspense>
      ),
      path: 'orders/item/:orderId/:tabName',
      permission: hasOrderAbilities() && isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'Order - task',
      menu: false,
      exact: true,
      secure: true,
      element: (
        <Suspense fallback={<Loading />}>
          <Order />
        </Suspense>
      ),
      path: 'orders/item/:orderId/:tabName/task/:taskId',
      permission: hasOrderAbilities() && isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'Order - tab entity',
      menu: false,
      exact: true,
      secure: true,
      element: (
        <Suspense fallback={<Loading />}>
          <Order />
        </Suspense>
      ),
      path: 'orders/item/:orderId/:tabName/:entityId',
      permission: hasOrderAbilities() && isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'Order create',
      menu: false,
      exact: true,
      secure: true,
      element: (
        <Suspense fallback={<Loading />}>
          <CreateOrder />
        </Suspense>
      ),
      path: 'orders/create',
      link: '/orders',
      permission: hasOrderAbilities() && isOnboardingClosed,
    },
    {
      name: 'Conversation Inbox',
      menu: false,
      exact: true,
      secure: true,
      path: 'conversations/conversation/:conversationId',
      permission: ability.can('read', 'Conversation') && isOnboardingClosed,
      element: (
        <Suspense fallback={<Loading />}>
          <Conversation />
        </Suspense>
      ),
    },
    {
      name: 'Profile',
      menu: false,
      path: 'profile/*',
      element: (
        <Suspense fallback={<Loading />}>
          <Profile />
        </Suspense>
      ),
      exact: true,
      secure: true,
      permission: isOnboardingClosed,
    },
    {
      name: 'Admin',
      menu: true,
      element: (
        <Suspense fallback={<Loading />}>
          <Admin />
        </Suspense>
      ),
      path: 'admin/*',
      link: '/admin',
      exact: true,
      secure: true,
      icon: TbSettings,
      childrenMenu: true,
      childrenGroups: ['Reports', 'User management', 'Communication', 'Sales', 'Salire'],
      permission: hasAdminAbilities() && isOnboardingClosed,
    },
    {
      type: 'page',
      name: 'Onboarding',
      menu: false,
      element: (
        <Suspense fallback={<Loading />}>
          <Onboarding />
        </Suspense>
      ),
      path: '',
      exact: true,
      secure: true,
      permission: !isOnboardingClosed,
      hideMenu: true,
    },
  ];

  const defaultPage: Page = {
    type: 'page',
    name: 'Default',
    menu: false,
    element: <NotFoundTenant />,
    path: '*',
    exact: true,
    secure: true,
    permission: isOnboardingClosed,
    hideMenu: true,
  };
  pages.push(defaultPage);

  const result: Page[] = [];

  // Only return pages an user has permission to
  pages
    .filter((page) => page.permission)
    .map((page) => {
      // Push to the resulting pages
      result.push(page);
      // If this page has child pages
      if (page.child) {
        page.child.map((child) => {
          // Check if the user has permission to this child
          if (child.permission) {
            result.push({
              ...child,
              childrenMenu: page.childrenMenu,
              parent: page.name,
              secure: page.secure,
              path: page.path + '/' + child.path,
            });
          }
        });
      }
    });

  return {
    pages: result,
  };
};
