import { ChakraStylesConfig, GroupBase } from 'chakra-react-select';

import { EntityName, FieldType, Language } from '__types/graphql';
import { OptionGroup } from 'components/CustomField/Pickers/Select/structures';

import { VariantStyle } from './interfaces/VariantStyle';

export const LOCAL_VALUES = {
  en: 'en-gb',
  nb: 'nb',
} as const;

export const ISO_COUNTRIES_LANGUAGE = {
  en: 'en',
  nb: 'no',
} as const;

export const DEFAULT_TIMEZONE = 'Europe/Oslo' as const;

export enum COOKIE_TYPE {
  ORGANIZATION = 'organization',
  ACCESS_TOKEN = 'accessToken',
}

export enum FULL_CALENDAR_VIEW {
  TIME_GRID_DAY = 'timeGridDay',
  TIME_GRID_WEEK = 'timeGridWeek',
  DAY_GRID_MONTH = 'dayGridMonth',
}

export const LANGUAGES = [
  { value: Language.nb, label: 'Norsk' },
  { value: Language.en, label: 'English' },
] as const;

export const DAY_AND_MONTH = 'dddd DD.MM' as const;

export const STANDARD_TIME_FORMAT = 'HH:mm' as const;

export const STANDARD_DATE_FORMAT = 'MM-DD-YYYY' as const;

export const DATE_PARAM_FORMAT = 'YYYY-MM-DD' as const;

export const API_DATE_FORMAT = 'YYYY-MM-DD' as const;

export const STANDARD_DATE_TIME_FORMAT = 'MM-DD-YYYY-HH:mm' as const;

export const IMPORT_DATA_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm' as const;

export const BRREG_SEARCH_URL = 'https://data.brreg.no/enhetsregisteret/api/enheter';

export enum LIST_VARIANT {
  DEFAULT = 'default',
  ROUNDED = 'rounded',
}

export const VARIANT_STYLES: Record<LIST_VARIANT, VariantStyle> = {
  [LIST_VARIANT.DEFAULT]: {
    edgePadding: 0,
    headerBottom: 3,
    headerTop: 3,
  },
  [LIST_VARIANT.ROUNDED]: {
    edgePadding: 5,
    headerBottom: 0,
    headerTop: 0,
    table: {
      borderCollapse: 'separate',
      borderSpacing: '0 15px',
    },
    tbody: {
      tr: {
        'position': 'relative',
        'zIndex': 0,
        'background': 'white',
        '&:after': {
          position: 'absolute',
          content: '""',
          width: '100%',
          height: '100%',
          background: 'white',
          left: 0,
          top: 0,
          zIndex: -1,
          borderRadius: '5px',
          boxShadow: '0px 0px 0 2px var(--chakra-colors-gray-100)',
        },
      },
    },
    tfoot: {
      tr: {
        'position': 'relative',
        'zIndex': 0,
        'background': 'gray.100',
        '&:after': {
          position: 'absolute',
          content: '""',
          width: '100%',
          height: '100%',
          background: 'gray.100',
          left: 0,
          top: 0,
          zIndex: -1,
          borderRadius: '5px',
          boxShadow: '0px 0px 0 2px var(--chakra-colors-gray-100)',
        },
      },
    },
  },
} as const;

export const FILTER_KEYS = {
  CUSTOMERS: {
    ADVANCED: {
      NAME: 'name',
      ORGNR: 'orgnr',
      CITY: 'city',
      COUNTY: 'county',
      MUNICIPALITY: 'municipality',
      COUNTRY: 'country',
      STATUS: 'status',
      LAST_ACTIVITY: 'lastActivity',
      CREATOR: 'creator',
      ASSIGNED: 'assigned',
      CATEGORY: 'category',
      PEOPLE_NAME: 'people.name',
      PEOPLE_ROLE: 'people.role',
      PEOPLE_EMAIL: 'people.email',
      PEOPLE_PHONE: 'people.phone',
      EVENTS_TYPE: 'events.type',
      EVENTS_STATUS: 'events.status',
      EVENTS_DATE: 'events.date',
      CONVERSATIONS_SUBJECT: 'conversations.subject',
      CONVERSATIONS_CREATED_AT: 'conversations.createdAt',
      SALES_STATUS: 'sales.status',
      SALES_PRICE: 'sales.price',
      SALES_BASKET_QTY: 'sales.basketQty',
      SALES_BASKET_ORIGINAL_PRICE: 'sales.basketOriginalPrice',
      SALES_BASKET_PRICE: 'sales.basketPrice',
      SALES_CREATED_AT: 'sales.createdAt',
      SALES_PRODUCTS: 'sales.products',
    },
    GENERAL: {
      CREATOR: 'creator',
      ASSIGNED: 'assigned',
      STATUS: 'status',
      CATEGORY: 'category',
      CREATED_AT: 'createdAt',
      USER_UPDATED_AT: 'userUpdatedAt',
    },
  },
  SALES: {
    GENERAL: {
      CREATOR: 'creator',
      STATUS: 'status',
      CONTRACT: 'contract',
      CREATED_AT: 'createdAt',
      EXPECTED_AT: 'expectedAt',
      EXPIRATION_DATE: 'expirationDate',
      STARTS_DATE: 'startsDate',
      CLOSED_AT: 'closedAt',
      PIPELINE_PROBABILITY: 'pipeline.probability',
      USER_UPDATED_AT: 'userUpdatedAt',
    },
  },
  ORDERS: {
    GENERAL: {
      STAGE: 'stage',
      STATUS: 'status',
      CREATED_AT: 'createdAt',
      USER_UPDATED_AT: 'userUpdatedAt',
    },
  },
  PEOPLE: {
    GENERAL: {
      CUSTOMER_STATUS: 'customerStatus',
      HAS_EMAIL_ADDRESS: 'hasEmailAddress',
      HAS_PHONE_NUMBER: 'hasPhoneNumber',
      CUSTOMER_CATEGORY: 'customerCategory',
      CREATED_AT: 'createdAt',
      USER_UPDATED_AT: 'userUpdatedAt',
    },
  },
  TIME_TRACKING: {
    GENERAL: {
      CUSTOMER: 'customer',
      USER: 'user',
      TASK: 'task',
      ORDER: 'order',
      DATE: 'day',
      CREATED_AT: 'createdAt',
      USER_UPDATED_AT: 'userUpdatedAt',
    },
  },
} as const;

export enum EVENT_TYPES_COLORS {
  prospecting = '#CEF0DC',
  planning = '#FDFFE1',
  production = '#B9F3E5',
  sales = '#DACFF8',
  customerRetention = '#F8EFCF',
  order = '#CFDAF8',
  support = '#CFF0F8',
  training = '#CFF0F8',
  finance = '#FFECFF',
  internal = '#F8DECF',
  private = '#E6F8CF',
  other = '#E8F2FB',
  service = '#DDDDDD',
  additional1 = '#F8CFE8',
  additional2 = '#F8CFCF',
  additional3 = '#DAEDFF',
  additional4 = '#DDDDDD',
}

export enum EVENT_TYPES_BORDER_COLORS {
  prospecting = '#00B44B',
  planning = '#FCD446',
  production = '#19D8AA',
  sales = '#A219C4',
  customerRetention = '#C48019',
  order = '#192AC4',
  support = '#19A3C4',
  training = '#19A3C4',
  finance = '#F957FC',
  internal = '#C48019',
  private = '#A2C419',
  other = '#BDD8F1',
  service = '#1A365D',
  additional1 = '#C419B3',
  additional2 = '#C41919',
  additional3 = '#3182CE',
  additional4 = '#1A365D',
}

export const TOKEN_KEY_NAME = import.meta.env.VITE_DATA_API as string;

export const TENANT_PATH = '/t/';

export enum CALLBACK_TYPE {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  MOVE = 'move',
}

export const MENU_ANIMATION_DURATION = '0.2s';

export const CUSTOM_FIELD_TYPES = {
  text: {
    type: FieldType.TEXT,
  },
  textBox: {
    type: FieldType.TEXTBOX,
  },
  number: {
    type: FieldType.NUMBER,
  },
  currency: {
    type: FieldType.CURRENCY,
  },
  checkbox: {
    type: FieldType.CHECKBOX,
  },
  value: {
    type: FieldType.SELECT,
  },
  multipleValue: {
    type: FieldType.SELECT,
    multiple: true,
  },
  date: {
    type: FieldType.DATE,
  },
  dateRange: {
    type: FieldType.DATE_RANGE,
  },
  user: {
    type: FieldType.ID,
    area: EntityName.USER,
  },
  multipleUser: {
    type: FieldType.ID,
    area: EntityName.USER,
    multiple: true,
  },
  customer: {
    type: FieldType.ID,
    area: EntityName.CUSTOMER,
  },
  multipleCustomer: {
    type: FieldType.ID,
    area: EntityName.CUSTOMER,
    multiple: true,
  },
  person: {
    type: FieldType.ID,
    area: EntityName.PERSON,
  },
  people: {
    type: FieldType.ID,
    area: EntityName.PERSON,
    multiple: true,
  },
  rating: {
    type: FieldType.RATING,
  },
} as const;

//eslint-disable-next-line
export const looseURLRegEx = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

export const SPECIAL_CHARACTERS = {
  COMMA: ',',
  HYPHEN: '-',
  DOUBLE_QUOTATION_MARK: '"',
  SQUARE_BRACKET_OPEN: '[',
} as const;

export const VITE_ORGANIZATION_SELECTION_PATH = import.meta.env.VITE_ORGANIZATION_SELECTION_PATH as string;
export const VITE_LOGIN_PATH = '/login';

export const chakraStyles: ChakraStylesConfig<OptionGroup, true, GroupBase<OptionGroup>> = {
  control: (provided) => ({
    ...provided,
    minH: 8,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({ ...provided, px: '3', py: 0 }),
  multiValue: (provided) => ({
    ...provided,
    rounded: 'full',
    fontSize: 'xs',
    minH: '20px',
    maxWidth: '150px',
  }),
  multiValueLabel: (provided) => ({ ...provided, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }),
  dropdownIndicator: (provided) => ({ ...provided, padding: '0 0.5em', bgColor: 'unset' }),
  placeholder: (provided) => ({ ...provided, fontSize: 'sm' }),
  option: (provided) => ({ ...provided, display: 'block', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }),
};

export const APP_ICON = 'https://salire.s3.eu-west-1.amazonaws.com/assets/aiflow-logo-icon.png';

export const MAX_NUMBER_OF_ATTACHMENTS = 4;
export const ATTACHMENT_FORMAT_TYPES = ['image/png', 'image/jpeg', 'application/pdf'];
