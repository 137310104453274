import { useQuery } from '@apollo/client';
import { Ability, ExtractSubjectType, MongoQuery, Subject, SubjectRawRule } from '@casl/ability';
import { apm } from '@elastic/apm-rum';
import moment from 'moment-timezone';
import en from 'moment/dist/locale/en-gb';
import nb from 'moment/dist/locale/nb';
import React from 'react';
import { useIntercom } from 'react-use-intercom';

import { AbilityContext } from 'client/ability';
import Loading from 'components/Loading';
import i18n from 'languages';
import { buildYupLocale } from 'languages/yup';
import { DEFAULT_TIMEZONE, LOCAL_VALUES } from 'shared/constants';

import { TenantStore } from '../store';
import GET_USER from './GetUser.graphql';
import TenantHelmet from './TenantHelmet';
import TenantPages from './TenantPages';
import { GetUser } from './__types/GetUser';
import { NotificationsStore } from './store';

const TenantApp = () => {
  const { boot } = useIntercom();
  const { setUser } = TenantStore.useStoreActions((actions) => actions);
  const { data, loading } = useQuery<GetUser>(GET_USER, {
    onCompleted: ({ getUser }) => {
      const { name, email, language, _id: id, organization } = getUser;
      setUser(getUser);

      boot({
        hideDefaultLauncher: true,
        name,
        email,
        userId: id,
        company: { name: organization?.name, companyId: organization?._id as string },
      });

      apm.setUserContext({ id, email });
      apm.addLabels({
        organizationId: organization?._id as string,
        language,
      });

      if (language) {
        i18n.changeLanguage(language, buildYupLocale);
      }
    },
  });

  const language = (data?.getUser?.language as keyof typeof LOCAL_VALUES | undefined) || 'en';
  const timezone = data?.getUser?.timezone || DEFAULT_TIMEZONE;
  const locales = {
    en: en._config,
    nb: nb._config,
  };
  moment.locale(LOCAL_VALUES[language], locales[language]);
  moment.tz.setDefault(timezone);

  if (loading) return <Loading />;
  // TODO: Fix ability fetching and user fetching for the sidebar
  return (
    <AbilityContext.Provider
      value={new Ability((data?.getUser?.abilities as Array<SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery<never>>>) || [])}
    >
      <NotificationsStore.Provider>
        <TenantHelmet />
        <TenantPages />
      </NotificationsStore.Provider>
    </AbilityContext.Provider>
  );
};

export default TenantApp;
